<template>
  <md-card class="form-template-details-card">
    <md-card-header class="md-card-header-text form-template-card-header">
        <div class="card-text">
          <h5 class="title">Form Template</h5>
        </div>
        <div class="versions-container">
          <md-chip
            class="md-info custom-chip"
            v-for="version in formTemplate.versions"
            :key="version.version"
            :class="isCurrentVersion(version) ? 'current-version-chip' : 'version-chip'"
            md-disabled
            @click="formTemplateVersionClicked(version)"
            >v{{ version.version }}</md-chip>
        </div>
    </md-card-header>
    <md-card-content class="custom-card-content">
        <div class="md-layout">
            <div class="md-layout-item md-size-50">
              <md-field>
                  <label>Form Template Key</label>
                  <md-input v-model="formTemplate.formTemplateKey" type="text" ref="formTemplateKeyField" :disabled="textFieldsDisabled">
                  </md-input>
              </md-field>
            </div>
            <!-- Tags -->
            <div class="md-layout-item md-layout md-size-50">
              <label class="md-layout-item md-size-10 md-form-label tags-label">Tags</label>
              <div class="md-layout-item md-size-90 tags-input nopadding">
                <md-field class="tags-field">
                  <vue-tags-input
                    class="avalia-tags-input"
                    v-model="tag"
                    :tags="getFormTemplateTags"
                    :autocomplete-items="autocompleteItems"
                    :placeholder="tagsInputPlaceholder"
                    @tags-changed="tagsChanged"
                    @before-adding-tag="newTagToValidate"
                  />
                </md-field>
              </div>
            </div>
            <!--
            <div class="md-layout-item md-size-50 versions-container">
              <md-chip
                class="md-info version-chip"
                v-for="version in formTemplate.versions"
                :key="version.version"
                md-disabled
                >v{{ version.version }}</md-chip>
            </div>
            -->
        </div>
    </md-card-content>
  </md-card>
</template>

<script>
import api from '@/api/api';
import notify from '@/utils/notificationsUtils';
import VueTagsInput from '@johmun/vue-tags-input';

export default {
  name: 'form-template-details-card',
  components: {
    // Breadcrumbs,
    VueTagsInput,
  },
  props: {
    formTemplate: {
      type: Object,
      required: true,
    },
    formTemplateVersion: {
      type: Object,
      required: true,
    },
    textFieldsDisabled: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      tagType: 'form template tag',
      tagsInputPlaceholder: '',
      tag: '',
      autocompleteItems: [],
      debounce: null,
    };
  },
  computed: {
    getFormTemplateTags() {
      return this.formTemplate.tags
        ? this.formTemplate.tags.map((t) => ({ text: t.name }))
        : [];
    },
  },
  watch: {
    tag: 'loadTags',
  },
  created() {
    // On created
  },
  mounted() {
    // On mounted
  },
  methods: {
    isCurrentVersion(version) {
      return version.version === this.formTemplateVersion.version;
    },
    formTemplateVersionClicked(version) {
      this.$emit('toFormTemplateVersion', version);
    },
    // Tags methods
    loadTags() {
      if (this.tag.length < 1) return;

      const url = `/tags?name=${this.tag.toLowerCase()}&type=${this.tagType}`;

      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        api.assess
          .get(url)
          .then((response) => {
            this.autocompleteItems = response.data.map((t) => ({
              text: t.name,
            }));
          })
          .catch((error) => {
            // console.log(error);
            const errorMessage = `Could not Fetch Tags : ${error.response.data.message || ''}`;
            notify.notifyError(errorMessage, this);
          });
      }, 500);
    },
    tagsChanged(newTags) {
      this.autocompleteItems = [];
      const formTemplate = { ...this.formTemplate, tags: newTags.map((tag) => tag.text) };
      this.$emit('updateFormTemplate', formTemplate);
    },
    newTagToValidate(obj) {
      const tag = obj.tag.text.toLowerCase();

      if (!this.formTemplate.tags.includes(tag)) {
        obj.addTag();
      }
      this.tag = '';
    },
  },
};
</script>

<style lang="scss" scoped>
.form-template-details-card{
  margin-bottom: 10px;
}
.versions-container{
  text-align: right;
}
.version-chip {
  cursor: pointer;
  //opacity: 0.6;
  color: $avalia-gray !important;
  background-color: white !important;

  &:hover {
    color: white !important;
    background-color: $avalia-gray !important;
    opacity: 0.8 !important;
    border-color: $avalia-gray !important;
  }
}
.current-version-chip {
  cursor:not-allowed;
  color: white !important;
  background-color: $avalia-gray !important;
  opacity: 0.8 !important;
  border-color: $avalia-gray !important;

  &:hover {
    opacity: 1 !important;
  }
}
.custom-chip{
  margin-left: 0px;
  margin-right: 4px;
  border-style: solid !important;
  border-width: 2px !important;
  font-size: 14px !important;
}
.form-template-card-header{
  display: flex;
}
.versions-container{
  margin-left: auto;
}

</style>
