<template>
  <div>
    <div v-for="(question, index) in formSection.questions" :key="index + question.questionKey" class="question-container">
      <form-template-question
        :question="question"
      />
    </div>
  </div>
</template>

<script>

import FormTemplateQuestion from '@/components/Avalia/FormTemplates/FormTemplate/FormTemplateQuestion.vue';

export default {
  name: 'form-template-section',
  components: {
    FormTemplateQuestion,
  },
  props: {
    formSection: Object,
  },
  methods: {
  },
};
</script>

<style lang="scss" scoped>

.question-container {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
</style>
