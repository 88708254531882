<template>
  <md-card>
    <md-card-header class="md-card-header-text">
        <div class="card-text">
        <h5 class="title">Version</h5>
        </div>
    </md-card-header>
    <md-card-content class="custom-card-content">
        <div class="md-layout">
            <div class="md-layout-item md-size-80">
              <md-field>
                  <label>Name</label>
                  <md-input v-model="formTemplateVersion.name" type="text" ref="formTemplateVersionNameField" :disabled="textFieldsDisabled">
                  </md-input>
              </md-field>
            </div>
            <div class="md-layout-item md-size-20">
              <md-field>
                  <label>Version</label>
                  <md-input v-model="formTemplateVersion.version" type="text" ref="formTemplateVersionNumberField" :disabled="textFieldsDisabled">
                  </md-input>
              </md-field>
            </div>
            <!--
            <div class="md-layout-item md-size-100 form-container">
              <slot name="form"></slot>
            </div> -->
        </div>
    </md-card-content>
  </md-card>
</template>

<script>
//import Breadcrumbs from '@/components/Avalia/Breadcrumbs/Breadcrumbs.vue';

export default {
  name: 'form-template-version-details-card',
  components: {
    // Breadcrumbs,
  },
  props: {
    formTemplateVersion: {
      type: Object,
      required: true,
    },
    textFieldsDisabled: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
    };
  },
  computed: {
  },
  watch: {},
  created() {
    // On created
  },
  mounted() {
    // On mounted
  },
  methods: {
  },
};
</script>

<style lang="scss" scoped>
.form-container{
  padding-top: 1rem;
}
</style>
