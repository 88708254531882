<template>
  <div class="md-layout">
    <div class="md-layout-item md-size-100">
      <label class="question-text">
        {{ question.label }}
      </label>
      <div v-if="hasQuestionDetail">
        <form-template-question-detail :detail="question.detail"> </form-template-question-detail>
      </div>
    </div>
    <div class="md-layout-item md-size-100 question-content-container">
      <form-template-question-content
        :question="question"
      />
    </div>
  </div>
</template>
<script>
import FormTemplateQuestionDetail from '@/components/Avalia/FormTemplates/FormTemplate/FormTemplateQuestionDetail.vue';

export default {
  name: 'form-template-question',
  components: {
    FormTemplateQuestionDetail,
  },
  props: {
    question: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      answers: [],
    };
  },
  computed: {
    hasQuestionDetail() {
      return this.question.detail != null && this.question.detail.length > 0;
    },
  },
  methods: {
  },
};
</script>
<style lang="scss" scoped>
.question-content-container {
  margin-top: 20px;
}
</style>
