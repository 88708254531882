<template>
  <div>
    <div v-if="loading">
      <loader/>
    </div>
    <div v-else>
      <div class="md-layout">
        <div class="md-layout-item md-layout md-size-100 md-medium-size-100">
          <div class="md-layout-item md-layout md-size-100 md-medium-size-100">
            <div class="md-layout-item md-size-100 md-medium-size-100 nopadding">
              <div class="md-layout-item md-size-100 md-medium-size-100">
                <breadcrumbs :sourceName="formTemplatesString" :sourceLink="formTemplatesPath" :currentName="formTemplateVersion.name"/>
              </div>
            </div>
            <div class="md-layout-item md-size-100 md-medium-size-100 nopadding">
              <form-template-details-card
                :formTemplate="formTemplate"
                :formTemplateVersion="formTemplateVersion"
                @toFormTemplateVersion="viewFormTemplateVersion"
                @updateFormTemplate="updateFormTemplate"
                @createTag="createTag"
              >
              </form-template-details-card>
            </div>
            <div class="md-layout-item md-size-100 md-medium-size-100 nopadding form-template-version-details-card">
              <form-template-version-details-card
                :formTemplateVersion="formTemplateVersion"
              >
              </form-template-version-details-card>
              <md-card>
                <md-card-content>

                    <div v-if="formHasSections">
                      <el-steps :active="activeSection" finish-status="success" align-center class="stepper">
                        <el-step
                          v-for="(section, index) in form.sections"
                          :key="section.number"
                          :title="section.title"
                          @click.native="() => setActiveSection(index)"
                        >
                        </el-step>
                      </el-steps>
                      <div class="animation-container">
                        <transition-group tag="div" :name="isSlideBack ? 'slideback' : 'slide'" mode="out-in">
                          <div v-for="section in [currentSection]" :key="section.number">
                            <form-template-section
                              :formSection="section"
                              class="form-section"
                            ></form-template-section>
                          </div>
                        </transition-group>
                      </div>
                    </div>
                    <div class="form-button-container">
                      <div>
                        <md-button
                          v-if="formHasSections"
                          :disabled="!hasPreviousSection"
                          class=" md-sm nav-button"
                          @click="onPrevious(activeSection)"
                        >
                          Previous
                        </md-button>
                        <md-button
                          v-if="formHasSections"
                          :disabled="!hasNextSection"
                          class="md-sm nav-button"
                          @click="onNext(activeSection)"
                        >
                          Next
                        </md-button>
                      </div>
                    </div>
                </md-card-content>
              </md-card>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/api/api';
import notify from '@/utils/notificationsUtils';
import Loader from '@/components/Avalia/Loader/Loader.vue';
import Breadcrumbs from '@/components/Avalia/Breadcrumbs/Breadcrumbs.vue';
import FormTemplateDetailsCard from '@/components/Avalia/FormTemplates/FormTemplateDetailsCard.vue';
import FormTemplateVersionDetailsCard from '@/components/Avalia/FormTemplates/FormTemplateVersionDetailsCard.vue';
import FormTemplateSection from '@/components/Avalia/FormTemplates/FormTemplate/FormTemplateSection.vue';
import { Step, Steps } from 'element-ui';

export default {
  name: 'form-template-details',
  components: {
    Loader,
    Breadcrumbs,
    FormTemplateDetailsCard,
    FormTemplateVersionDetailsCard,
    FormTemplateSection,
    [Step.name]: Step,
    [Steps.name]: Steps,
  },
  props: {},
  data() {
    return {
      formTemplateVersionId: null,
      loading: true,
      formTemplatesString: 'Form Templates',
      formTemplatesPath: '/formTemplates',
      isSlideBack: false,
      activeSection: 0,
    };
  },
  computed: {
    formTemplate() {
      return this.$store.getters['formTemplates/GET_FORM_TEMPLATE'];
    },
    formTemplateVersion() {
      return this.$store.getters['formTemplates/GET_FORM_TEMPLATE_VERSION'];
    },
    form() {
      return this.$store.getters['formTemplates/GET_FORM_TEMPLATE_VERSION_FORM'];
    },
    formHasSections() {
      return this.form.sections && this.sectionsLength > 0;
    },
    sectionsLength() {
      return this.form.sections.length;
    },
    hasNextSection() {
      return this.activeSection + 1 < this.sectionsLength;
    },
    hasPreviousSection() {
      return this.activeSection > 0;
    },
    currentSection() {
      return this.form.sections[this.activeSection];
    },
  },
  watch: {
    '$route.params.formTemplateVersionId': {
      handler(newValue, oldValue) {
        this.formTemplateVersionId = +this.$route.params.formTemplateVersionId;
        if (this.formTemplateVersion.id !== this.formTemplateVersionId) {
          this.fetchFormTemplateData();
        }
      },
      immediate: false,
    },
  },
  created() {
    // On created
    this.formTemplateVersionId = +this.$route.params.formTemplateVersionId;
    this.fetchFormTemplateData();
  },
  mounted() {
    // On mounted
  },
  methods: {
    fetchTags() {
      return this.$store.dispatch('tags/fetchFormTemplateTags', this);
    },
    createTag(tag) {
      api.assess
        .post('/tags', tag)
        .then((response) => {
          this.fetchTags();
        })
        .catch((error) => {
          const errorMessage = `Could not Add Tag : ${error.response.data.message || ''}`;
          notify.notifyError(errorMessage, this);
        });
    },
    updateFormTemplate(formTemplate) {
      api.assess
        .put(`/formTemplates/${formTemplate.id}`, formTemplate)
        .then((response) => {
          this.fetchFormTemplateById(formTemplate.id).then(() => {
            const successMessage = 'Form Template successfully updated';
            notify.notifySuccess(successMessage, this);
          });
        })
        .catch((error) => {
          // console.log(error);
          const errorMessage = `Could not update Form Template: ${error.response.data.message || ''}`;
          notify.notifyError(errorMessage, this);
        });
    },
    fetchFormTemplateData() {
      this.loading = true;
      this.fetchFormTemplateVersion(this.formTemplateVersionId)
        .then(() => this.fetchFormTemplateByFormTemplateKey(this.formTemplateVersion.formTemplateKey))
        .then(() => this.fetchFormTemplateVersionForm(this.formTemplateVersionId))
        .then(() => {
          this.loading = false;
        });
    },
    fetchFormTemplateVersion(formTemplateVersionId) {
      return this.$store.dispatch('formTemplates/fetchFormTemplateVersion', { formTemplateVersionId, vm: this });
    },
    fetchFormTemplateByFormTemplateKey(formTemplateKey) {
      return this.$store.dispatch('formTemplates/fetchFormTemplateByFormTemplateKey', { formTemplateKey, vm: this });
    },
    fetchFormTemplateById(formTemplateId) {
      return this.$store.dispatch('formTemplates/fetchFormTemplateById', { formTemplateId, vm: this });
    },
    fetchFormTemplateVersionForm(formTemplateVersionId) {
      return this.$store.dispatch('formTemplates/fetchFormTemplateVersionForm', { formTemplateVersionId, vm: this });
    },
    viewFormTemplateVersion(version) {
      this.$track('fe_viewFormTemplateDetails', version);
      const location = {
        name: 'Form Template Version Details',
        params: { formTemplateVersionId: version.id },
      };
      // In current setup the new page opens on a login page so currently not usable
      /*
      if (event.metaKey || event.ctrlKey) {
        const routeData = this.$router.resolve(location);
        window.open(routeData.href, '_blank');
      } else { */
      this.$router.push(location);
      //}
    },
    onNext(activeSection) {
      if (this.hasNextSection) {
        this.setActiveSection(this.activeSection + 1);
      }
    },
    onPrevious(activeSection) {
      if (this.hasPreviousSection) {
        this.setActiveSection(this.activeSection - 1);
      }
    },
    setActiveSection(sectionNumber) {
      if (sectionNumber > this.activeSection) {
        this.isSlideBack = false;
      } else {
        this.isSlideBack = true;
      }
      this.activeSection = sectionNumber;
      this.scrollBackUp();
    },
    /** UTILS **/
    scrollBackUp() {
      window.scrollTo({
        top: 280,
        left: 0,
        behavior: 'smooth',
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.stepper {
  margin-top: 20px;
  margin-bottom: 14px;
}
.animation-container {
  position: relative;

  overflow: hidden;
  transition: none;
  will-change: height;
}
/* TRANSITION */
.slide-leave-active,
.slide-enter-active {
  transition: 1s;
}
.slide-enter {
  transform: translate(100%, 0%);
}
.slide-leave,
.slideback-leave {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.slide-leave-to {
  transform: translate(-100%, 0%);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
/*
.answer-loading {
  cursor: progress;
}*/
.slideback-leave-active,
.slideback-enter-active {
  transition: 1s;
}
.slideback-enter {
  transform: translate(-100%, 0%);
}
.slideback-leave-to {
  transform: translate(100%, 0%);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.nav-button {
  margin-left: 0.2rem;

  color: $avalia-gray !important;
  background-color: white !important;
  border-style: solid !important;
  border-width: 2px !important;
  &:hover {
    color: white !important;
    background-color: $avalia-gray !important;
    opacity: 0.8 !important;
    border-color: $avalia-gray !important;
  }
}
.form-button-container {
  display: flex;
  justify-content:flex-end;
  padding: 0rem 0.9rem 0rem;;
}
.form-template-version-details-card{
  z-index:0;
}
/*
.form-content{
  overflow: hidden;
  transition: none;
  will-change: height;
}*/

</style>
<!-- element ui css override -->
<style lang="scss">
.el-step__title {
  line-height: 1.5em;
}
</style>
