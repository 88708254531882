<template>
  <span class="breadcrumbs">
    <div class="custom-breadcrumbs">
      <router-link :to="sourceLink" active-class="custom-breadcrumbs-font">
        <span class="custom-breadcrumbs-link">{{ sourceName }}</span>
      </router-link>
    </div>
    <md-icon class="breadcrumb-icon"><i class="fas fa-angle-right"></i></md-icon>
    <div class="custom-breadcrumbs custom-breadcrumbs-font">
      {{ currentName }}
    </div>
  </span>
</template>
<script>
export default {
  name: 'breadcrumbs',
  props: {
    sourceLink: String,
    sourceName: String,
    currentName: String,
  },
};
</script>
<style lang="scss" scoped>
.breadcrumbs {
  display: -webkit-box;
  margin-bottom: 10px;
}
.custom-breadcrumbs-font {
  font-weight: 400;
  font-size: 1.25rem!important;
  text-transform: uppercase!important;
  color: #7a7a7a !important;
}
.custom-breadcrumbs-link {
  &:hover {
    text-decoration: underline;
  }
}
.custom-breadcrumbs {
  padding-top: 0.1rem;
  padding-right: 0.8rem;
}
.breadcrumb-icon {
  padding-right: 0.8rem;
}
</style>
