<template>
  <div class="md-layout">
      <div class="md-layout-item md-size-100 nopadding">
          <ul class="detail-list">
            <li v-html="detail[0]">
            </li>
          </ul>
          <div v-if="hasMoreThanOneQuestionDetail">
            <a v-if="!showMoreActivated" @click="activateShowMore" class="show-more-text">
              Show more</a>
            <span v-if="showMoreActivated">
              <ul class="detail-list">
                <li v-for="(item, index) in detail.slice(1)" :key="index">
                  <p>{{ item }}</p>
                </li>
              </ul>
              <a v-if="showMoreActivated" @click="deactivateShowMore" class="show-more-text">
              Show less</a>
            </span>
          </div>
      </div>
  </div>
</template>

<script>

export default {
  name: 'form-template-question-detail',
  props: {
    detail: Array,
  },
  data() {
    return {
      showMoreActivated: false,
    };
  },
  computed: {
    hasMoreThanOneQuestionDetail() {
      return this.detail.length > 1;
    },
  },
  watch: {},
  created() {
    // On created
  },
  mounted() {
    // On mounted
  },
  methods: {
    activateShowMore() {
      this.showMoreActivated = true;
    },
    deactivateShowMore() {
      this.showMoreActivated = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.detail-list{
  padding-left: 1rem;;
}
</style>
<style lang="scss">
/* OVERRIDE THEME */
ul ul {
  margin-left: 20px;
}
.show-more-text{
  cursor:pointer;
}
</style>
